<template lang="pug">
.brand-wrapper.brand-audience
  .container-fluid
    .d-flex
      om-heading.pb-5(h1) {{ $t('audience') }}
      FailedIntegrationNotification(v-if="bulkResyncVisible" :errorCount="errorCount")

    om-pills.mt-5(:pills="pills" :selected="tab" @select="tab = $event")
    .mt-5
      transition(name="fade")
        .audience-tab-content(v-if="!tabChange")
          Subscribers(
            v-if="tab === 'leads'"
            :hasErrors="!!errorCount"
            @filter-selected-domains-change="handleSelectedDomainsChange"
            @filter-selected-campaigns-change="handleSelectedCampaignsChange"
            @filter-date-change="handleDateFilterChange"
            @filter-failed-change="handleFailedFilterChange"
          )
          UserSegments(v-else)
  integration-resync-modal(
    :failedIntegrationResyncService="failedIntegrationResyncService"
    :integrationResyncData="integrationResyncData"
    @integration-resync="resync"
  )
</template>

<script>
  import { mapGetters } from 'vuex';
  import Subscribers from '@/components/Audience/Subscribers.vue';
  import UserSegments from '@/components/Audience/UserSegments.vue';
  import IntegrationResyncModal from '@/components/FailedIntegration/Modal.vue';
  import FailedIntegrationNotification from '@/components/FailedIntegration/Notification.vue';
  import FailedIntegrationResyncService from '@/services/integrations/resync';

  export default {
    components: {
      Subscribers,
      UserSegments,
      IntegrationResyncModal,
      FailedIntegrationNotification,
    },
    data: () => ({
      tabs: ['leads', 'segments'],
      tab: 'leads',
      tabChange: false,
      failedIntegrationResyncService: null,
      integrationResyncData: [],
      errorCount: 0,
      failedStatusFilterOn: false,
      filter: {
        domainIds: [],
        campaignIds: [],
        dates: {
          start: null,
          end: null,
        },
      },
    }),
    computed: {
      ...mapGetters(['databaseId']),
      pills() {
        return this.tabs.map((key) => ({
          key,
          text: this.$t(key),
        }));
      },
      bulkResyncVisible() {
        return this.tab === 'leads' && this.failedStatusFilterOn;
      },
    },
    watch: {
      tab() {
        this.updateBrowserUrl();
        this.tabChange = true;
        setTimeout(() => {
          this.tabChange = false;
        }, 300);
      },
      filter: {
        handler(filter) {
          this.getResyncData(filter);
        },
        deep: true,
      },
    },
    beforeMount() {
      const { tab } = this.$route.query;
      if (tab && this.tabs.includes(tab)) {
        this.tab = tab;
      }
    },
    created() {
      this.failedIntegrationResyncService = new FailedIntegrationResyncService(
        this.databaseId,
        this.$apollo,
      );
      this.getResyncData({});
    },
    mounted() {
      if (this.$route.params.openIntegrationResyncModal) {
        this.openIntegrationResyncModal();
      }
      this.$bus.$on('refetch-integration-resync-data', () => {
        this.getResyncData({});
      });
    },
    methods: {
      handleSelectedDomainsChange(domainIds) {
        this.filter.domainIds = domainIds;
      },
      handleSelectedCampaignsChange(campaignIds) {
        this.filter.campaignIds = campaignIds;
      },
      handleDateFilterChange(dates) {
        this.filter.dates = dates;
      },
      handleFailedFilterChange(status) {
        this.failedStatusFilterOn = status;
      },
      async getResyncData({ domainIds = [], campaignIds = [], dates = null }) {
        this.integrationResyncData =
          await this.failedIntegrationResyncService.getIntegrationResyncData({
            domainIds,
            campaignIds,
            dates,
          });

        this.errorCount = 0;
        this.integrationResyncData.forEach(({ errorCount }) => {
          this.errorCount += errorCount;
        });
      },
      resync({ isNew, totalLeads, campaignId, serviceInstance }) {
        this.$modal.show('lead-sync-modal', {
          type: 'period-select',
          isNew,
          totalLeads,
          campaignId,
          serviceInstance,
        });
      },
      updateBrowserUrl() {
        const currentUrl = new URL(window.location.href);
        if (this.tabs[0] !== this.tab) {
          currentUrl.searchParams.set('tab', this.tab);
        } else {
          currentUrl.searchParams.delete('tab');
        }
        if (window.location.href !== currentUrl.toString()) {
          window.history.pushState({}, 'om-audience', currentUrl.toString());
        }
      },
      openIntegrationResyncModal() {
        this.$modal.show('failed-integration-resync');
      },
    },
  };
</script>
<style lang="sass" scoped>
  .brand-audience
    width: calc(100vw - 1rem)
    @media (min-width: 768px)
      width: calc(100vw - 5.25rem)

  ::v-deep .failed-integration-notification
    width: fit-content
    height: 44px
    margin-left: auto
    .notification-action-wrapper
      display: flex
      align-items: center
      max-height: 32px
      margin-left: 45px
      margin-top: 2px
      .btn-secondary
        height: 32px
</style>
